@import url('https://fonts.googleapis.com/css2?family=Playfair+Display:wght@400;500;600&display=swap');
$brand-blue: #00005A;

.threshold {
    background-image: linear-gradient(rgb(230, 228, 255), rgb(255, 255, 255), rgb(173, 173, 255));
    width: 100%;
    font-family: "Playfair Display";
    font-weight: 400;    

    >#logo-wrapper {
        padding: 20px;
        width: 50px;

        @media only screen and (max-width: 800px) {
            width: 50px;
        }

        >#logo {
            width: 100%;
        }
    }

    >#header-buttons {
        position: absolute;
        right: 30px;
        top: 30px;
        
        @media only screen and (max-width: 800px) {
            top: 35px;
        }

        >.header-button {
            float: left;
            margin-left: 10px;
            font-size: 20px;
            color: #aaa;
            cursor: pointer;
            transition: all 0.2s;

            @media only screen and (max-width: 800px) {
                font-size: 15px;
            }

            &:hover {
                color: #555;
            }
            
            &.active {
                color: $brand-blue;
                text-decoration: underline;

                &:hover {
                    color: #555;
                }
            }
        }
    }

    >#threshold-verbiage {
        width: 500px;
        padding: 20px;
        margin: 30px;
        margin-top: 100px;
        position: relative;
        top: 100px;
        font-size: 40px;
        color: $brand-blue;
        
        @media only screen and (max-width: 1600px) {
            z-index: 10;
            background-color: white;
            width: 500px;
            top: 0px;
            border: 2px solid $brand-blue;
        }

        
        @media only screen and (max-width: 800px) {
            width: calc(100% - 40px);
            margin: 0;
            font-size: 28px;
            padding: 20px;
            background-color: transparent;
            border: none;
        }

        >.emphasis {
            font-weight: 600;
        }
    }

    >#threshold-overlay {
        width: 1000px;
        position: absolute;
        right: 25px;
        bottom: 0px;

        @media only screen and (max-width: 800px) {
            width: 100%;
            right: 0;
        }
    }
}


.background {
  position: absolute;
  bottom: 0;
  height: 500px !important;
  left: 0;
  z-index: 1;

  
        @media only screen and (max-width: 800px) {
            bottom: -0px !important;
        }
}