@import url(https://fonts.googleapis.com/css2?family=Playfair+Display:wght@400;500;600&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    -webkit-animation: App-logo-spin infinite 20s linear;
            animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@-webkit-keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.threshold{background-image:linear-gradient(#e6e4ff, #fff, #adadff);width:100%;font-family:"Playfair Display";font-weight:400}.threshold>#logo-wrapper{padding:20px;width:50px}@media only screen and (max-width: 800px){.threshold>#logo-wrapper{width:50px}}.threshold>#logo-wrapper>#logo{width:100%}.threshold>#header-buttons{position:absolute;right:30px;top:30px}@media only screen and (max-width: 800px){.threshold>#header-buttons{top:35px}}.threshold>#header-buttons>.header-button{float:left;margin-left:10px;font-size:20px;color:#aaa;cursor:pointer;transition:all 0.2s}@media only screen and (max-width: 800px){.threshold>#header-buttons>.header-button{font-size:15px}}.threshold>#header-buttons>.header-button:hover{color:#555}.threshold>#header-buttons>.header-button.active{color:#00005A;text-decoration:underline}.threshold>#header-buttons>.header-button.active:hover{color:#555}.threshold>#threshold-verbiage{width:500px;padding:20px;margin:30px;margin-top:100px;position:relative;top:100px;font-size:40px;color:#00005A}@media only screen and (max-width: 1600px){.threshold>#threshold-verbiage{z-index:10;background-color:white;width:500px;top:0px;border:2px solid #00005A}}@media only screen and (max-width: 800px){.threshold>#threshold-verbiage{width:calc(100% - 40px);margin:0;font-size:28px;padding:20px;background-color:transparent;border:none}}.threshold>#threshold-verbiage>.emphasis{font-weight:600}.threshold>#threshold-overlay{width:1000px;position:absolute;right:25px;bottom:0px}@media only screen and (max-width: 800px){.threshold>#threshold-overlay{width:100%;right:0}}.background{position:absolute;bottom:0;height:500px !important;left:0;z-index:1}@media only screen and (max-width: 800px){.background{bottom:-0px !important}}

